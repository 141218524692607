<template>
  <div>
    <global-header></global-header>
    <div class="header">
      <h1 style="color: #FFF; padding: 60px 0 10px;">帮助中心</h1>
      <div>帮助您解决问题</div>
      <div style="width: 500px;border: 15px solid rgba(0,0,0,.3);padding: 0;margin: 15px auto;">
        <el-input class="primary-append" placeholder="输入您要查找的关键词" v-model="keyWord" @keyup.enter="searchProblem">
          <template slot="append">
            <el-button type="primary"
                       :class="{'active-search-btn': !!keyWord}"
                       @click="searchProblem">
              综合搜索
            </el-button>
          </template>
        </el-input>
      </div>
      <div class="pad-b20" style="">
        <span>搜索热词：</span>
        <span v-for="(word,index) in hotSearchWords" :key="index" style="margin-right: 10px;cursor:pointer;"
              @click="() => {keyWord = word;searchProblem()}">{{ word }}</span>
      </div>
    </div>
    <div class="sctp-container sctp-pad-tb20 fz-14">
      <el-row :gutter="10">
        <el-col :span="6">
          <div class="catalog-item fz-22" style="background: #6899EC;color: #FFF;">常见问题分类</div>
          <div
            v-for="item in helpTypeList"
            :key="item.id"
            style="cursor: pointer"
          >
            <div class="catalog-item space-between"
                 @click="() => {openCloseType(item.id);chooseType(item.id)}"
                 :class="{'choosed': item.id === typeid}"
            >
              <span>{{ item.typename }}</span>
              <img :src="item.open ? '/images/helpcenter/down.png' : '/images/helpcenter/up.png'" height="15px"
                   style="margin-top: 2px;"/>
            </div>
            <div
              class="catalog-item sub-item"
              v-for="subtype in item.subTypeList"
              :key="subtype.id"
              :class="{'hide': !subtype.open, 'choosed': subtype.id === typeid}"
              @click="chooseType(subtype.id)"
            >{{ subtype.typename }}
            </div>
          </div>
        </el-col>
        <el-col :span="18">
          <div class="breadcrumb" style="padding: 10px; background-color: #eaeaea;">
            <a href="#">帮助中心</a>
            <span v-show="!!navProblem.type"> > </span>
            <a href="#" @click="chooseType(navProblem.type.id)" v-show="!!navProblem.type">{{
                navProblem.type &&
                navProblem.type.typename
              }}</a>
            <span v-show="!!navProblem.subtype"> > </span>
            <a href="#" @click="() => {chooseType(navProblem.subtype.id);openCloseType(navProblem.type.id, true)}"
               v-show="!!navProblem.subtype">{{ navProblem.subtype && navProblem.subtype.typename }}</a>
          </div>
          <div style="padding: 10px;">
            <div
              class="problem-item"
              v-show="!currentProblem.title"
              v-for="item in helpProblemList"
              :key="item.id"
              @click="setProblem(item.title, item.content)"
            >
              {{ item.title }}
            </div>
            <div v-show="currentProblem.title">
              <h2 style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px dashed #ccc;">
                {{ currentProblem.title }}</h2>
              <div class="rich-text" v-html="currentProblem.content" style="overflow: auto;"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <global-footer></global-footer>
  </div>
</template>
<script>
const config = require('../../resources/js/config');
export default {
  name: 'help-center',
  components: {
    GlobalHeader: () => import('../../components/common/GlobalHeader'),
    SearchBar: () => import('./HomeSearchBar'),
    GlobalFooter: () => import('../../components/common/GlobalFooter')
  },
  data() {
    return {
      keyWord: null,
      typeid: null,
      helpProblemList: [],
      hotSearchWords: [],
      helpTypeList: [{
        id: null,
        parentid: null,
        sortid: null,
        typename: null,
        open: null,
        subTypeList: [{
          id: null,
          parentid: null,
          sortid: null,
          typename: null,
          open: null
        }]
      }],
      currentProblem: {
        title: null,
        content: null
      }
    }
  },
  computed: {
    navProblem() {
      let type = null
      let subtype = null
      for (const helpType of this.helpTypeList) {
        for (const subHelpType of helpType.subTypeList) {
          if (subHelpType.id === this.typeid) {
            subtype = subHelpType
            break
          }
        }
        if (!!subtype || helpType.id === this.typeid) {
          type = helpType
          break
        }
      }
      return {
        type,
        subtype
      }
    }
  },
  methods: {
    getHelpTypeList() {
      this.$request.post({
        reqcode: '1077',
        reqdesc: '获取帮助类型'
      }).then(res => {
        this.helpTypeList = res.retdata
        this.hotSearchWords = res.hotSearchWords
      })
    },
    getHelpProblemList(keyWord) {
      this.problem = {}
      this.$request.post({
        reqcode: '1078',
        reqdesc: '获取帮助问题',
        typeid: this.typeid,
        userid:this.$store.state.account.user?this.$store.state.account.user.userId:"",
        content: keyWord
      }).then(res => {
        if (keyWord) {
          res.retdata.forEach(item => {
            item.content = item.content.replace(new RegExp(keyWord, 'g'), '<span style="color: red">' + keyWord + '</span>')
          })
        }
        if (res.retdata.length === 1) {
          this.setProblem(res.retdata[0].title, res.retdata[0].content)
          this.helpProblemList = []
        } else {
          this.setProblem()
          this.helpProblemList = res.retdata
        }
      })
    },
    openCloseType(id, open) {
      this.helpTypeList.forEach((type, index) => {
        if (type.id === id) {
          type.open = open != null ? open : !type.open
          type.subTypeList.forEach(type => {
            type.open = open != null ? open : !type.open
          })
          this.helpTypeList.splice(index, 1, type)
          return false
        }
      })
    },
    chooseType(typeid) {
      this.typeid = typeid
      this.getHelpProblemList()
    },
    setProblem(title, problem = '') {
      this.currentProblem = {
        title: title,
        content: problem
      }
    },
    searchProblem() {
      if (!this.keyWord) {
        this.$message.warning('请输入搜索内容')
        return
      }
      this.typeid = null
      this.setProblem()
      this.getHelpProblemList(this.keyWord)
    }
  },
  beforeMount() {
    this.getHelpTypeList()
  }
}
</script>
<style scoped lang="scss">
.header {
  text-align: center;
  background-image: url("/images/helpcenter/help-serbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #FFF;
}

.header > div {
  padding: 10px;
}

.primary-append {
  ::v-deep .el-input-group__append {
    color: white;
    border-color: $main-color;
  }
}

.catalog-item {
  padding: 10px 20px;
  color: #5a5a5a;
  background-color: #eaeaea;
  border-bottom: 1px solid #FFF;
}

.catalog-item.sub-item {
  background: #FFF;
  border-bottom: 1px solid #eaeaea;
}

.catalog-item.choosed {
  color: #6899EC;
}

.problem-item {
  border-bottom: 1px dashed #ccc;
  padding: 10px 0;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.hide {
  display: none;
}

.active-search-btn {
  background: rgb(255, 153, 0) !important;
  border-color: rgb(255, 153, 0) !important;
  margin-right: -21px !important;
  height: 40px !important;
  color: #FFF !important;
}
</style>
